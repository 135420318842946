"use client";
import { UserAuth } from "@/context/AuthContext";
import { axiosInstance } from "@/lib/constants";
import React, { useEffect } from "react";

function AuthorizationProvider({ children }) {
  const { user } = UserAuth();
  useEffect(() => {
    if (localStorage.getItem("gaAccessToken")) {
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${JSON.parse(localStorage.getItem("gaAccessToken"))}`;
    } else {
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("accessToken")}`;
    }
    // axiosInstance.defaults.headers.common[
    //   "Authorization"
    // ] = `Bearer ${localStorage.getItem("accessToken")}`;
  }, []);

  return <div>{children}</div>;
}

export default AuthorizationProvider;
