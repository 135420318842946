import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { axiosInstance } from "./constants";
import { toast } from "sonner";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const splitName = (name) => {
  const words = name.split(" ");

  const lastName = words[0];
  const firstName = words.slice(1).join(" ");
  return { firstName, lastName };
};
export const setCredentials = (data) => {
  if (typeof document !== "undefined") {
    document.localStorage?.setItem("userInfo", JSON.stringify(data));
  }
};
export const removeCredentials = () => {
  if (typeof document !== "undefined") {
    document.localStorage?.removeItem("userInfo");
  }
};
export const getCredentials = () => {
  const data = window?.localStorage?.getItem("userInfo");
  if (data) {
    return JSON.parse(data);
  } else return null;
};
export const getStoredAccessToken = () => {
  const data = window?.localStorage?.getItem("accessToken");
  if (data) {
    return data;
  } else return null;
};
export const getStoredGA_AccessToken = () => {
  const data = window?.localStorage?.getItem("gaAccessToken");
  if (data) {
    return data;
  } else return null;
};
export const deleteStoredGA_AccessToken = () => {
  const data = window?.localStorage?.removeItem("gaAccessToken");
  if (data) {
    return data;
  } else return null;
};

export const getNewAccessToken = async () => {
  try {
    const response = await axiosInstance.get("/auth/refresh");
    localStorage.setItem("accessToken", response.data.data.accessToken);
    localStorage.setItem("userInfo", JSON.stringify(response.data.data.user));

    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`;

    // window.location.reload();
  } catch (error) {}
};

export const extractCode = (id) => {
  const match = id.match(/INV-(\w+)/);
  return match ? match[1] : null;
};

export const extractEscrowCode = (id) => {
  const match = id.match(/EST-(\w+)/);
  return match ? match[1] : null;
};
export const extractPaymentLinkCode = (id) => {
  const match = id.match(/DPL-(\w+)/);
  return match ? match[1] : null;
};

export const calculateTotalProductPrice = (invoices) => {
  let paidTotal = 0;
  let unpaidTotal = 0;

  invoices.forEach((invoice) => {
    // Calculate total product price for each invoice
    const invoiceTotal = invoice.items.reduce((acc, product) => {
      return acc + product.qty * product.price;
    }, 0);

    // Check the status of the invoice and add the total product price accordingly
    if (invoice.status === "paid") {
      paidTotal += invoiceTotal;
    } else if (invoice.status === "unpaid") {
      unpaidTotal += invoiceTotal;
    }
  });

  return [paidTotal, unpaidTotal];
};

export const calculateFees = (price) => {
  let fees;
  const PERCENT = 0.01;
  const CAP_ONE = 10000;
  const CAP_TWO = 150000;

  if (price >= CAP_ONE && price < CAP_TWO) {
    fees = price * PERCENT + 100;
  } else if (price >= CAP_TWO) {
    fees = 1500;
  } else {
    fees = 0;
  }

  return fees;
};

export const handleError = (status) => {
  if (status === 403) {
    getNewAccessToken();
  }
  if (status === 401) {
    const user = getCredentials();
    const gaAccessToken = getStoredGA_AccessToken();
    if (user && gaAccessToken) {
      deleteStoredGA_AccessToken();
      window.location.href = "/auth/login";
    } else {
      window.location.href = "/auth/login";
    }
  }
};

export function stopScroll(yes) {
  if (yes) {
    document.body.style.overflow = "hidden";
    // Enable scrolling
  } else {
    document.body.style.overflow = "auto";
    // Disable scrolling
  }
}

export const formatDateString = (dateString) => {
  const date = new Date(dateString);

  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");

  return `${year}-${month}-${day}, ${hours}:${minutes}`;
};

export const hasteriskString = (inputString) => {
  const length = Math.min(inputString.length, 40);
  return "*".repeat(length);
};

export const replaceIdInUrl = (newId, url) => {
  const urlParts = url.split("/");
  const tailParts = urlParts[4].split("?");
  const newUrl = `${urlParts[0]}//${urlParts[2]}/r/${newId}?${tailParts[1]}`;
  return newUrl;
};

export const startTokenRefreshInterval = () => {
  const intervalMinutes = 14;
  const intervalMilliseconds = intervalMinutes * 60 * 1000;

  // Check if there is a last execution time in localStorage
  const lastExecutionTime = localStorage.getItem("lastAccessTokenTime");
  const now = Date.now();

  if (lastExecutionTime) {
    const elapsed = now - parseInt(lastExecutionTime, 10);

    if (elapsed < intervalMilliseconds) {
      const remainingTime = intervalMilliseconds - elapsed;
      setTimeout(() => {
        getNewAccessToken();
        localStorage.setItem("lastAccessTokenTime", now.toString());
        setInterval(getNewAccessTokenWithStorage, intervalMilliseconds);
      }, remainingTime);
    } else {
      getNewAccessTokenWithStorage();
      setInterval(getNewAccessTokenWithStorage, intervalMilliseconds);
    }
  } else {
    getNewAccessTokenWithStorage();
    setInterval(getNewAccessTokenWithStorage, intervalMilliseconds);
  }
};

const getNewAccessTokenWithStorage = () => {
  getNewAccessToken();
  localStorage.setItem("lastAccessTokenTime", Date.now().toString());
};

export function formatCurrency(amountX100) {
  // Convert the amount to a fixed two-decimal string
  const amount = amountX100 / 100;
  const formattedAmount = amount.toFixed(2);

  // Split the amount into naira and kobo parts
  const [naira, kobo] = formattedAmount.split(".");

  // Format the naira part with commas for thousands
  const formattedNaira = naira.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Return the formatted currency string with ₦ and kobo precision
  return `₦${formattedNaira}.${kobo} `;
}

export const getKYCFormID = (tier) => {
  if (tier === "TIER_1") {
    return "3N0kGb";
  } else if (tier === "TIER_2") {
    return "3jL2yY";
  } else if (tier === "TIER_3") {
    return "wbOEQ7";
  } else return null;
};

export const formatErrorString = (str) => {
  if (str) {
    return str.replace(/_/g, " ");
  } else {
    return "";
  }
};
export const dateFormat = (date) => {
  if (date.getUTCFullYear() == new Date().getUTCFullYear()) {
    if (date.getUTCMonth() == new Date().getUTCMonth()) {
      return new Date(date).toLocaleString("en-US", {
        day: "numeric",
        month: "short",
        hour: "numeric",
        minute: "numeric",
      });
    }
    return new Date(date).toLocaleString("en-US", {
      day: "numeric",
      month: "short"
    });
  }
  return new Date(date).toLocaleString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};
export const handlePostError = (error) => {
  if (error?.code === "ERR_NETWORK") {
    toast.dismiss();
    toast.error("Network Error");
  } else if (
    error?.response?.status === 401 ||
    error?.response?.status === 403
  ) {
    toast.dismiss();

    toast.error("Token Expired, Login");
    window.location.href = "/auth/login";
  } else if (error?.response?.data?.code) {
    toast.dismiss();

    toast.error(formatErrorString(error?.response?.data?.code));
  } else {
    toast.dismiss();

    toast.error("An error occured");
  }
};
