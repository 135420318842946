"use client";
import { useContext, createContext, useState, useEffect } from "react";
import {
  signInWithRedirect,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  getAuth,
  getRedirectResult,
} from "firebase/auth";
import { getAnalytics, isSupported } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { BASE_URL, firebaseConfig } from "@/lib/constants";
import axios from "axios";

const AuthContext = createContext();
export const AuthContextProvider = ({ children }) => {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const [user, setUser] = useState(null);
  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider);
  };
  const signInWithGoogle = async (user) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/user/details/${user.email}`
      );
      localStorage.setItem("userInfo", JSON.stringify(response.data.data));
      localStorage.setItem(
        "gaAccessToken",
        JSON.stringify(`GA-${user.accessToken}`)
      );
      window.location.href = "/dashboard";
    } catch (error) {}
  };
  useEffect(() => {
    getRedirectResult(auth)
      .then((response) => {
        if (!response) return;

        setUser(response.user);
        signInWithGoogle(response.user);

        // Your code here
      })
      .catch((error) => {});
  }, []);

  const logOut = () => {
    signOut(auth);
  };

  /////////////////////////

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, [user]);

  ////////////////////////////

  /////////////////////////////

  useEffect(() => {
    const initializeFirebase = async () => {
      if (await isSupported()) {
        // const app = initializeApp(firebaseConfig);
        getAnalytics(app);
      } else {
      }
    };

    initializeFirebase();
  }, []);

  return (
    <AuthContext.Provider value={{ user, googleSignIn, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
