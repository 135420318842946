// export const BASE_URL =
//   "http://nodejs-example-express-rds.eba-wwjueu2j.eu-west-1.elasticbeanstalk.com";

// export const BASE_URL = "https://api.dev.centiiv.com";
// export const BASE_URL = "https://ctbackend-dev.eu-west-1.elasticbeanstalk.com";
// export const BASE_URL = "https://ctbackend-dev.cyclic.app";
const getUrl = () => {
  if (process.env.NEXT_PUBLIC_LOCAL) {
    return "http://localhost:3000"
  } 
  if (process.env.NEXT_PUBLIC_NODE_ENV === "development") {
    return "https://api.dev.centiiv.com";
  } else if (process.env.NEXT_PUBLIC_NODE_ENV === "production") {
    return "https://api.prod.centiiv.com";
  } else {
    return "https://api.preprod.centiiv.com";
  }
};

export const BASE_URL = getUrl();

import axios from "axios";
import { BiMoneyWithdraw } from "react-icons/bi";
import { FaCoins } from "react-icons/fa";
import {
  deleteStoredGA_AccessToken,
  getCredentials,
  getNewAccessToken,
  getStoredGA_AccessToken,
} from "./utils";
export const PaymentOptions = [
  {
    id: 0,
    icon: <BiMoneyWithdraw size={30} className="icon" />,
    name: <span>Bank Transfer</span>,
    link: "payment/fiat",
  },

  {
    id: 1,
    icon: <FaCoins size={30} className="icon" />,
    name: <span>Pay with Stablecoin</span>,
    link: "payment/stablecoin",
  },
];

export const axiosInstance = axios.create({
  baseURL: `${BASE_URL}/api/v1`,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": BASE_URL,
    "Origin-Allow-Credentials": true,
    "Access-Control-Allow-Credentials": true,
  },
});
export const swrFetcher = (url) =>
  axiosInstance.get(url).then((res) => res.data);
// .catch((err) => {
//   if (err.response?.status === 403) {
//     getNewAccessToken();
//     cons
//   }
//   if (err.response?.status === 401) {
//     const user = getCredentials();
//     const gaAccessToken = getStoredGA_AccessToken();
//     if (user && gaAccessToken) {
//       deleteStoredGA_AccessToken();
//       window.location.href = "/auth/login";
//     } else {
//       window.location.href = "/auth/login";
//     }
//   }
// }
//);
export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};
export const swrPaymentFetcher = (url) =>
  axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": BASE_URL,
        "Origin-Allow-Credentials": true,
        "Access-Control-Allow-Credentials": true,
      },
    })
    .then((res) => res.data);

export const customerDummyList = [
  {
    name: "John Doe",
    phone: "123-456-7890",
    email: "john.doe@example.com",
    created_at: "03/30/2021 12:50",
  },
  {
    name: "Alice Smith",
    phone: "456-789-0123",
    email: "alice.smith@example.com",
    created_at: "03/30/2021 12:50",
  },
  {
    name: "Michael Johnson",
    phone: "789-012-3456",
    email: "michael.johnson@example.com",
    created_at: "03/30/2021 12:50",
  },
  {
    name: "Emily Brown",
    phone: "012-345-6789",
    email: "emily.brown@example.com",
    created_at: "03/30/2021 12:50",
  },
  {
    name: "Daniel Martinez",
    phone: "345-678-9012",
    email: "daniel.martinez@example.com",
    created_at: "03/30/2021 12:50",
  },
  {
    name: "Sophia Wilson",
    phone: "678-901-2345",
    email: "sophia.wilson@example.com",
    created_at: "03/30/2021 12:50",
  },
  {
    name: "David Thompson",
    phone: "901-234-5678",
    email: "david.thompson@example.com",
    created_at: "03/30/2021 12:50",
  },
  {
    name: "Emma Garcia",
    phone: "234-567-8901",
    email: "emma.garcia@example.com",
    created_at: "03/30/2021 12:50",
  },
  {
    name: "Matthew Rodriguez",
    phone: "567-890-1234",
    email: "matthew.rodriguez@example.com",
    created_at: "03/30/2021 12:50",
  },
  {
    name: "Olivia Lopez",
    phone: "890-123-4567",
    email: "olivia.lopez@example.com",
    created_at: "03/30/2021 12:50",
  },
  {
    name: "Ethan Lee",
    phone: "123-456-7890",
    email: "ethan.lee@example.com",
    created_at: "03/30/2021 12:50",
  },
  {
    name: "Ava Harris",
    phone: "456-789-0123",
    email: "ava.harris@example.com",
    created_at: "03/30/2021 12:50",
  },
  {
    name: "Noah Clark",
    phone: "789-012-3456",
    email: "noah.clark@example.com",
    created_at: "03/30/2021 12:50",
  },
  {
    name: "Isabella Walker",
    phone: "012-345-6789",
    email: "isabella.walker@example.com",
    created_at: "03/30/2021 12:50",
  },
  {
    name: "James Young",
    phone: "345-678-9012",
    email: "james.young@example.com",
    created_at: "03/30/2021 12:50",
  },
];

export const escrowList = [
  {
    transaction_id: "CPP10478",
    customer_name: "John Doe",
    price_naira: 5000,
    date: "27 Feb 2023",
    status: "Completed",
  },
  {
    transaction_id: "CPP10479",
    customer_name: "Alice Smith",
    price_naira: 8000,
    date: "27 Feb 2023",
    status: "Pending",
  },
  {
    transaction_id: "CPP10480",
    customer_name: "Emily Johnson",
    price_naira: 3500,
    date: "28 Feb 2023",
    status: "Completed",
  },
  {
    transaction_id: "CPP10481",
    customer_name: "Michael Brown",
    price_naira: 6000,
    date: "28 Feb 2023",
    status: "Pending",
  },
  {
    transaction_id: "CPP10482",
    customer_name: "Sarah Wilson",
    price_naira: 4500,
    date: "1 Mar 2023",
    status: "Cancelled",
  },
  {
    transaction_id: "CPP10483",
    customer_name: "David Jones",
    price_naira: 7000,
    date: "1 Mar 2023",
    status: "Pending",
  },
];
